import {
  CHECKOUT_ABANDON_AMAZON_PAY,
  CHECKOUT_ADD_NEW_PAYMENT_CLICK,
  CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT,
  CHECKOUT_ADD_PAYMENT_INSTRUMENT,
  CHECKOUT_AFTERPAY_BUTTON_CLICK,
  CHECKOUT_AFTERPAY_ERROR,
  CHECKOUT_AFTERPAY_IMPRESSION,
  CHECKOUT_AFTERPAY_POPUP_CLOSED,
  CHECKOUT_AFTERPAY_SUCCESS,
  CHECKOUT_BEGIN_CONFIRMING_PAYPAL,
  CHECKOUT_CHANGE_AMAZON_PAY_ADDRESS_CLICK,
  CHECKOUT_CHANGE_AMAZON_PAY_PAYMENT_CLICK,
  CHECKOUT_CHANGE_QUANTITY,
  CHECKOUT_CLOSE_ADDRESSLIST_MODAL,
  CHECKOUT_CLOSE_PAYMENT,
  CHECKOUT_CLOSE_PAYMENT_MODAL,
  CHECKOUT_CLOSE_SELECT_ADDRESS,
  CHECKOUT_CLOSE_SUGGESTED_ADDRESS_MODAL,
  CHECKOUT_DELETE_PAYMENT_INSTRUMENT,
  CHECKOUT_EDIT_ADDRESS,
  CHECKOUT_FETCH_AKITA_ESTIMATE,
  CHECKOUT_FETCH_LAT_LONG,
  CHECKOUT_FETCH_ORDERS_BY_PURCHASE_ID,
  CHECKOUT_FETCH_SYMPHONY_CONTENT,
  CHECKOUT_HAD_SHIP_OPTION_AUTO_SELECTED,
  CHECKOUT_INIT_AMAZON_PAY,
  CHECKOUT_JUST_LOADED,
  CHECKOUT_LOAD_AUTOCOMPLETE_SUGGESTIONS,
  CHECKOUT_ON_ADD_NEW_BILLING_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK,
  CHECKOUT_ON_ADD_NEW_SHIP_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK,
  CHECKOUT_ON_ASIN_HAS_GONE_OOS,
  CHECKOUT_ON_CHANGE_BILLING_ADDRESS_CLICK,
  CHECKOUT_ON_CHANGE_PAYMENT_CLICK,
  CHECKOUT_ON_CHANGE_SHIPPING_CLICK,
  CHECKOUT_ON_HIDE_BILLING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK,
  CHECKOUT_ON_HIDE_BILLING_ADDRESS_MODAL_CLICK,
  CHECKOUT_ON_HIDE_NEW_SHIPPING_ADDRESS_MODAL_CLICK,
  CHECKOUT_ON_HIDE_SHIPPING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK,
  CHECKOUT_ON_MAX_STEP_IS_CART_STEP,
  CHECKOUT_ON_MOVE_TO_FAVORITES_CLICK,
  CHECKOUT_ON_PROMISE_DATE_HAS_CHANGED,
  CHECKOUT_ON_SELECT_SHIPPING_SPEED,
  CHECKOUT_ON_SHOW_ADD_NEW_SHIPPING_ADDRESS_MODAL_CLICK,
  CHECKOUT_ON_USE_BILLING_ADDRESS_CLICK,
  CHECKOUT_ON_USE_SHIPPING_ADDRESS_CLICK,
  CHECKOUT_ON_VIEW_SALES_TAX_CLICK,
  CHECKOUT_PAYPAL_AUTHENTICATION_NOT_APPROVED,
  CHECKOUT_PAYPAL_BUTTON_CLICK,
  CHECKOUT_PAYPAL_CONFIRM_ON_PENDING_STATUS,
  CHECKOUT_PAYPAL_ERROR,
  CHECKOUT_PAYPAL_GENERATE_REDIRECT,
  CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK,
  CHECKOUT_RECEIVE_ORDER,
  CHECKOUT_REDEEM_REWARDS_POINTS,
  CHECKOUT_REMOVE_ITEM,
  CHECKOUT_SEND_TO_DESIRED_PAGE,
  CHECKOUT_SEND_TO_MAX_AVAILABLE_STEP,
  CHECKOUT_SEND_TO_NEW_ADDRESS_WHEN_NO_SAVED_ADDRESSES,
  CHECKOUT_SET_IS_AFTER_PAY_BUTTON_LOADED,
  CHECKOUT_SET_IS_AFTER_PAY_BUTTON_UNABLE_TO_LOAD,
  CHECKOUT_SET_IS_AFTER_PAY_LOADED,
  CHECKOUT_SET_IS_AFTER_PAY_UNABLE_TO_LOAD,
  CHECKOUT_SET_IS_AMAZON_PAY_LOADED,
  CHECKOUT_SET_IS_AMAZON_PAY_REDIRECTING,
  CHECKOUT_SET_IS_AMAZON_PAY_UNABLE_TO_LOAD,
  CHECKOUT_SET_IS_ENROLLED_IN_REWARDS,
  CHECKOUT_SET_IS_PAYPAL_LOADED,
  CHECKOUT_SET_IS_PAYPAL_UNABLE_TO_LOAD,
  CHECKOUT_SET_PAYMENT_METHOD_TYPE,
  CHECKOUT_SHIP_OPTIONS_LOADED,
  CHECKOUT_STORE_PAYPAL_AUTHENTICATION_SOURCE,
  CHECKOUT_TOGGLE_IS_PRIMARY,
  CHECKOUT_TOGGLE_ITEMS,
  CHECKOUT_TOGGLE_PROMO_BOX,
  CHECKOUT_TRACK_CV,
  CHECKOUT_TRACK_NOT_MAX_STEP,
  CHECKOUT_UPDATE_CC_EXPIRATION,
  CHECKOUT_UPDATE_VIP_PREFERENCE,
  CHECKOUT_USE_NEW_ADDRESS,
  CHECKOUT_USE_PAYMENT_METHOD,
  CHECKOUT_USE_SUGGESTED_ADDRESS,
  CHECKOUT_VERIFY_ADDRESS_PAGEVIEW,
  CHECKOUT_VIEW_SHIPPING_DOWNGRADE,
  CHECKOUT_VIP_TERMS_CLICK,
  CONFIGURE_CHECKOUT,
  DELETE_CHECKOUT_ADDRESS,
  PLACE_ORDER,
  REQUEST_CHECKOUT_ADDRESSES,
  REQUEST_CHECKOUT_PAYMENTS,
  REQUEST_CHECKOUT_REDEEMABLE_REWARDS,
  RESET_CHECKOUT,
  SAVE_BILLING_BEFORE_PAYMENT,
  SAVE_CHECKOUT_ADDRESS,
  SAVE_CHECKOUT_AFTERPAY_PAYMENT_METHOD,
  SAVE_CHECKOUT_PAYMENT_METHOD,
  SET_CHECKOUT_USE_AS_DEFAULTS,
  SET_SELECTED_ADDRESS_ID,
  SET_SELECTED_BILLING_ADDRESS_ID,
  SET_SELECTED_PAYMENT_INSTRUMENT_ID,
  SET_SELECTED_SHIPPING_ADDRESS_ID,
  SET_SELECTED_SUGGESTED_ADDRESS_ID,
  SET_USE_DISCOUNT_BALANCE,
  SET_USE_PROMO_BALANCE,
  TRACK_CHECKOUT_DELIVERY_PROMISE,
  VERIFY_CHECKOUT_CREDIT_CARD
} from 'store/ducks/checkout/types';
import { USE_PROMO_BALANCE_SESSION_STORAGE_KEY } from 'constants/appConstants';
import { BILLING_ADDRESS_SECTION, PAYMENT_SECTION, SHIPPING_SECTTION } from 'constants/amethyst';
import { AFTER_PAY_BUTTON_JS_TYPE, AFTER_PAY_JS_TYPE, AMAZON_PAY_JS_TYPE, PAYPAL_JS_TYPE } from 'constants/externalJavascriptFiles';
import { saveToSessionStorage } from 'helpers/ClientUtils';

// ----- review items
export const onChangeQuantityEvent = (newQuantity, originalQuantity, product) => ({
  type: CHECKOUT_CHANGE_QUANTITY,
  newQuantity,
  originalQuantity,
  product
});
export const onMoveToFavoritesClick = payload => ({
  type: CHECKOUT_ON_MOVE_TO_FAVORITES_CLICK,
  payload
});
export const onRemoveItem = product => ({
  type: CHECKOUT_REMOVE_ITEM,
  product
});
export const onToggleItemsEvent = isShown => ({
  type: CHECKOUT_TOGGLE_ITEMS,
  isShown
});

// ----- address related
export const onAddNewBillingAddressFromModalAddressListClick = () => ({
  type: CHECKOUT_ON_ADD_NEW_BILLING_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK
});
export const onAddNewShippingAddressFromModalAddressListClick = () => ({
  type: CHECKOUT_ON_ADD_NEW_SHIP_ADDRESS_FROM_MODAL_ADDRESS_LIST_CLICK
});
export const onAddOrEditAddressEvent = ({ passedValidation, addressId, addressType, hasAddressLine2, pageType }) => ({
  type: CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT,
  passedValidation,
  addressId,
  addressType,
  hasAddressLine2,
  pageType
});
export const onAddOrEditBillingAddressErrorEvent = () => ({
  type: CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT,
  passedValidation: false,
  addressId: null,
  addressType: 1
});
export const onAddOrEditShippingAddressErrorEvent = () => ({
  type: CHECKOUT_ADD_OR_EDIT_ADDRESS_EVENT,
  passedValidation: false,
  addressId: null,
  addressType: 2
});
export const onChangeBillingAddressClick = () => ({
  type: CHECKOUT_ON_CHANGE_BILLING_ADDRESS_CLICK,
  changeToSection: BILLING_ADDRESS_SECTION
});
export const onChangeShippingAddressClick = () => ({
  type: CHECKOUT_ON_CHANGE_SHIPPING_CLICK,
  changeToSection: SHIPPING_SECTTION
});
export const onCloseAddressModal = isBilling => ({
  type: CHECKOUT_CLOSE_ADDRESSLIST_MODAL,
  isBilling,
  sourceSection: isBilling ? BILLING_ADDRESS_SECTION : SHIPPING_SECTTION
});
export const onCloseSelectShippingAddressListClick = () => ({
  type: CHECKOUT_CLOSE_SELECT_ADDRESS,
  sourceSection: SHIPPING_SECTTION
});
export const onCloseSuggestedAddressModal = () => ({
  type: CHECKOUT_CLOSE_SUGGESTED_ADDRESS_MODAL
});
export const onDeleteBillingAddressClick = addressId => ({
  type: DELETE_CHECKOUT_ADDRESS,
  addressId
});
export const onDeleteShipAddressClick = addressId => ({
  type: DELETE_CHECKOUT_ADDRESS,
  addressId
});
export const onEditAddressClick = (editAddressId, editAddressIndex) => ({
  type: CHECKOUT_EDIT_ADDRESS,
  editAddressId,
  editAddressIndex
});
export const onFetchLatLong = query => ({
  type: CHECKOUT_FETCH_LAT_LONG,
  query
});
export const onHideBillingAddressFormModalFromModalAddressListClick = () => ({
  type: CHECKOUT_ON_HIDE_BILLING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK
});
export const onHideBillingAddressModalClick = () => ({
  type: CHECKOUT_ON_HIDE_BILLING_ADDRESS_MODAL_CLICK
});
export const onHideNewShippingAddressModalClick = () => ({
  type: CHECKOUT_ON_HIDE_NEW_SHIPPING_ADDRESS_MODAL_CLICK
});
export const onHideShippingAddressFormModalFromModalAddressListClick = () => ({
  type: CHECKOUT_ON_HIDE_SHIPPING_ADDRESS_FORM_MODAL_FROM_MODAL_ADDRESS_LIST_CLICK
});
export const onLoadAddressAutocompleteSuggestions = ({ query, near, countryCode }) => ({
  type: CHECKOUT_LOAD_AUTOCOMPLETE_SUGGESTIONS,
  query,
  near,
  countryCode
});
export const onSelectedBillingAddress = selectedAddressId => ({
  type: SET_SELECTED_BILLING_ADDRESS_ID,
  selectedAddressId
});
export const onSelectedShippingAddress = selectedAddressId => ({
  type: SET_SELECTED_SHIPPING_ADDRESS_ID,
  selectedAddressId
});
export const onSelectedSuggestedBillingAddress = (selectedAddressId, selectedAddressIndex) => ({
  type: SET_SELECTED_SUGGESTED_ADDRESS_ID,
  selectedAddressId,
  selectedAddressIndex,
  addressType: 2
});
export const onSelectedSuggestedShippingAddress = (selectedAddressId, selectedAddressIndex) => ({
  type: SET_SELECTED_SUGGESTED_ADDRESS_ID,
  selectedAddressId,
  selectedAddressIndex,
  addressType: 1
});
export const onSendToNewAddressWhenNoSavedAddresses = () => ({
  type: CHECKOUT_SEND_TO_NEW_ADDRESS_WHEN_NO_SAVED_ADDRESSES
});
export const onShowAddNewShippingAddressModalClick = () => ({
  type: CHECKOUT_ON_SHOW_ADD_NEW_SHIPPING_ADDRESS_MODAL_CLICK
});
export const onUseBillingAddressClick = (selectedAddressId, addressIndex) => ({
  type: CHECKOUT_ON_USE_BILLING_ADDRESS_CLICK,
  selectedAddressId,
  addressIndex
});
export const onUseNewBillingAddressClick = () => ({
  type: CHECKOUT_USE_NEW_ADDRESS
});
export const onUseShippingAddressClick = (selectedAddressId, addressIndex) => ({
  type: CHECKOUT_ON_USE_SHIPPING_ADDRESS_CLICK,
  selectedAddressId,
  addressIndex
});
export const onViewSalesTaxClick = () => ({
  type: CHECKOUT_ON_VIEW_SALES_TAX_CLICK
});
export const onUseSuggestedAddressClick = (addressType, selectedAddressId) => ({
  type: CHECKOUT_USE_SUGGESTED_ADDRESS,
  addressType,
  selectedAddressId
});
export const onVerifyAddressPageView = () => ({
  type: CHECKOUT_VERIFY_ADDRESS_PAGEVIEW
});
export const requestAddresses = () => ({ type: REQUEST_CHECKOUT_ADDRESSES });
export const saveBillingAddress = () => ({
  type: SAVE_CHECKOUT_ADDRESS,
  isBilling: true
});
export const saveShippingAddress = () => ({
  type: SAVE_CHECKOUT_ADDRESS,
  isBilling: false
});
export const saveBillingBeforePayment = cb => ({
  type: SAVE_BILLING_BEFORE_PAYMENT,
  isBilling: true,
  cb
});
export const setSelectedAddressId = selectedAddressId => ({
  type: SET_SELECTED_ADDRESS_ID,
  selectedAddressId
});

// ----- payment related
export const onAddNewPaymentClickEvent = () => ({
  type: CHECKOUT_ADD_NEW_PAYMENT_CLICK
});
export const onAddPaymentInstrumentErrorEvent = makeDefaultPaymentInstrument => ({
  type: CHECKOUT_ADD_PAYMENT_INSTRUMENT,
  paymentInstrumentId: null,
  makeDefaultPaymentInstrument,
  passedValidation: false
});
export const onAddPaymentInstrumentEvent = (paymentInstrumentId, makeDefaultPaymentInstrument, passedValidation) => ({
  type: CHECKOUT_ADD_PAYMENT_INSTRUMENT,
  paymentInstrumentId,
  makeDefaultPaymentInstrument,
  passedValidation
});
export const onChangePaymentClick = () => ({
  type: CHECKOUT_ON_CHANGE_PAYMENT_CLICK,
  changeToSection: PAYMENT_SECTION
});
export const onCloseNewPaymentEvent = () => ({
  type: CHECKOUT_CLOSE_PAYMENT_MODAL
});
export const onClosePaymentEvent = () => ({
  type: CHECKOUT_CLOSE_PAYMENT,
  sourceSection: PAYMENT_SECTION
});
export const onDeletePaymentInstrumentClick = ({ isExpired, paymentInstrumentId }) => ({
  type: CHECKOUT_DELETE_PAYMENT_INSTRUMENT,
  paymentInstrumentId,
  isExpired
});
export const onToggleIsPrimary = isPrimary => ({
  type: CHECKOUT_TOGGLE_IS_PRIMARY,
  isPrimary
});
export const onTogglePromoBox = () => ({ type: CHECKOUT_TOGGLE_PROMO_BOX });
export const onUpdateExpiration = () => ({
  type: CHECKOUT_UPDATE_CC_EXPIRATION
});
export const onUsePaymentMethodClickEvent = (paymentInstrumentId, hasVerifyCreditCardError, index) => ({
  type: CHECKOUT_USE_PAYMENT_METHOD,
  paymentInstrumentId,
  hasVerifyCreditCardError,
  index
});
export const onUseShippingOptionClick = promise => ({
  type: CHECKOUT_ON_SELECT_SHIPPING_SPEED,
  promise
});
export const requestPayments = () => ({ type: REQUEST_CHECKOUT_PAYMENTS });
export const savePaymentInstrument = ({ instrument, addressId, updatingAddress, clearPurchaseDoc }) => ({
  type: SAVE_CHECKOUT_PAYMENT_METHOD,
  instrument,
  addressId,
  updatingAddress,
  clearPurchaseDoc
});
export const setPaymenthMethodType = paymentMethodType => ({
  type: CHECKOUT_SET_PAYMENT_METHOD_TYPE,
  paymentMethodType
});
export const setSelectedPaymentInstrumentId = ({ paymentInstrumentId }) => ({
  type: SET_SELECTED_PAYMENT_INSTRUMENT_ID,
  selectedPaymentInstrumentId: paymentInstrumentId
});
export const setUsePromoBalance = usePromoBalance => {
  saveToSessionStorage(USE_PROMO_BALANCE_SESSION_STORAGE_KEY, usePromoBalance);
  return { type: SET_USE_PROMO_BALANCE, usePromoBalance };
};
export const setUseDiscount = useDiscountBalance => ({ type: SET_USE_DISCOUNT_BALANCE, useDiscountBalance });
export const verifyCreditCard = ({ number, paymentInstrumentId }) => ({
  type: VERIFY_CHECKOUT_CREDIT_CARD,
  number,
  paymentInstrumentId
});

// ----- flow control
export const fetchCheckoutContent = () => ({
  type: CHECKOUT_FETCH_SYMPHONY_CONTENT
});
export const onMaxStepIsCartStep = () => ({
  type: CHECKOUT_ON_MAX_STEP_IS_CART_STEP
});
export const onSendToDesiredPage = (step, query) => ({
  type: CHECKOUT_SEND_TO_DESIRED_PAGE,
  step,
  query
});
export const onSendToMaxAvailableStep = step => ({
  type: CHECKOUT_SEND_TO_MAX_AVAILABLE_STEP,
  step
});
export const resetCheckout = () => ({ type: RESET_CHECKOUT });
export const trackEventNotMaxAvailable = step => ({
  type: CHECKOUT_TRACK_NOT_MAX_STEP,
  step
});

// ----- order confirmation
export const onFetchOrdersByPurchaseId = pId => ({
  type: CHECKOUT_FETCH_ORDERS_BY_PURCHASE_ID,
  pId
});
export const onReceiveOrdersByPurchaseId = ({ orderId, deliveryDateRangeDisplayMessage, earliestArrivalDate, latestArrivalDate }) => ({
  type: CHECKOUT_RECEIVE_ORDER,
  orderId,
  deliveryDateRangeDisplayMessage,
  earliestArrivalDate,
  latestArrivalDate
});
export const onPostPurchaseOfferCancelClick = () => ({
  type: CHECKOUT_POST_PURCHASE_OFFER_CANCEL_CLICK
});

// ----- redeemable rewards
export const onDetermineIsEnrolledInRewards = isEnrolledInRewards => ({
  type: CHECKOUT_SET_IS_ENROLLED_IN_REWARDS,
  isEnrolledInRewards
});
export const onFetchAkitaEstimate = () => ({
  type: CHECKOUT_FETCH_AKITA_ESTIMATE
});
export const onRequestRedeemableRewards = () => ({
  type: REQUEST_CHECKOUT_REDEEMABLE_REWARDS
});
export const onRedeemPoints = spendPoints => ({
  type: CHECKOUT_REDEEM_REWARDS_POINTS,
  spendPoints
});

// ----- shipping
export const onCheckoutPromiseDateHasChanged = ({ duration, name, purchaseId, promiseDate, prevPromiseDate, sameOtherThanType, type }) => ({
  type: CHECKOUT_ON_PROMISE_DATE_HAS_CHANGED,
  duration,
  name,
  purchaseId,
  promiseDate,
  prevPromiseDate,
  sameOtherThanType,
  changeType: type
});
export const onCheckoutShipOptionsLoaded = () => ({
  type: CHECKOUT_SHIP_OPTIONS_LOADED
});
export const onHadShipOptionAutoSelected = () => ({
  type: CHECKOUT_HAD_SHIP_OPTION_AUTO_SELECTED
});
export const onViewShippingDowngrade = ({ dollarAmount, offeredSpeed, rewardsPoints }) => ({
  type: CHECKOUT_VIEW_SHIPPING_DOWNGRADE,
  dollarAmount,
  offeredSpeed,
  rewardsPoints
});
export const trackCheckoutDeliveryPromise = () => ({
  type: TRACK_CHECKOUT_DELIVERY_PROMISE
});

// ----- place order
export const placeCheckoutOrder = () => ({ type: PLACE_ORDER });

// ----- after pay
export const onAfterPayIsLoaded = () => ({
  type: CHECKOUT_SET_IS_AFTER_PAY_LOADED
});
export const onAfterPayIsUnableToLoad = () => ({
  type: CHECKOUT_SET_IS_AFTER_PAY_UNABLE_TO_LOAD,
  externalPaymentJsType: AFTER_PAY_JS_TYPE
});
export const onAfterPayButtonIsLoaded = () => ({
  type: CHECKOUT_SET_IS_AFTER_PAY_BUTTON_LOADED
});
export const onAfterPayButtonIsUnableToLoad = () => ({
  type: CHECKOUT_SET_IS_AFTER_PAY_BUTTON_UNABLE_TO_LOAD,
  externalPaymentJsType: AFTER_PAY_BUTTON_JS_TYPE
});
export const saveAfterpayPaymentInstrument = ({ instrument }) => ({
  type: SAVE_CHECKOUT_AFTERPAY_PAYMENT_METHOD,
  instrument
});
export const onAfterpayImpression = ({ doesCartContainGiftCard, isAfterpayEligibleByPrice }) => ({
  type: CHECKOUT_AFTERPAY_IMPRESSION,
  doesCartContainGiftCard,
  isAfterpayEligibleByPrice
});
export const onAfterpayError = () => ({ type: CHECKOUT_AFTERPAY_ERROR });
export const onAfterpayPopupClosed = () => ({
  type: CHECKOUT_AFTERPAY_POPUP_CLOSED
});
export const onAfterpayButtonClick = ({ isChargeSummary, isOrderTotal }) => ({
  type: CHECKOUT_AFTERPAY_BUTTON_CLICK,
  isChargeSummary,
  isOrderTotal
});
export const onAfterpaySuccess = () => ({ type: CHECKOUT_AFTERPAY_SUCCESS });

// ----- amazon pay
export const onAmazonPayIsRedirecting = () => ({
  type: CHECKOUT_SET_IS_AMAZON_PAY_REDIRECTING,
  isAmazonPayRedirecting: true
});
export const onAmazonPayIsLoaded = () => ({
  type: CHECKOUT_SET_IS_AMAZON_PAY_LOADED
});
export const onAmazonPayIsUnableToLoad = () => ({
  type: CHECKOUT_SET_IS_AMAZON_PAY_UNABLE_TO_LOAD,
  externalPaymentJsType: AMAZON_PAY_JS_TYPE
});
export const onInitAmazonPay = ({ isAutoInit, isExpressCheckout, isChargeSummary, isOrderTotal }) => ({
  type: CHECKOUT_INIT_AMAZON_PAY,
  isAutoInit,
  isExpressCheckout,
  isChargeSummary,
  isOrderTotal
});
export const onAbandonAmazonPay = () => ({ type: CHECKOUT_ABANDON_AMAZON_PAY });
export const onChangeAmazonPayPaymentClick = () => ({
  type: CHECKOUT_CHANGE_AMAZON_PAY_PAYMENT_CLICK
});
export const onChangeAmazonPayAddressClick = () => ({
  type: CHECKOUT_CHANGE_AMAZON_PAY_ADDRESS_CLICK
});

// ----- paypal
export const onPayPalIsLoaded = () => ({ type: CHECKOUT_SET_IS_PAYPAL_LOADED });
export const onPayPalIsUnableToLoad = () => ({
  type: CHECKOUT_SET_IS_PAYPAL_UNABLE_TO_LOAD,
  externalPaymentJsType: PAYPAL_JS_TYPE
});
export const onReceivePayPalAuthenticationSource = authenticationSourceId => ({
  type: CHECKOUT_STORE_PAYPAL_AUTHENTICATION_SOURCE,
  authenticationSourceId
});
export const onBeginConfirmingPayPal = () => ({
  type: CHECKOUT_BEGIN_CONFIRMING_PAYPAL
});
export const onPaypalButtonClick = ({ isExpressCheckout, isChargeSummary, isOrderTotal }) => ({
  type: CHECKOUT_PAYPAL_BUTTON_CLICK,
  isExpressCheckout,
  isChargeSummary,
  isOrderTotal
});
export const onPaypalGenerateRedirect = () => ({
  type: CHECKOUT_PAYPAL_GENERATE_REDIRECT
});
export const onPaypalError = () => ({ type: CHECKOUT_PAYPAL_ERROR });
export const onPaypalConfirmOnPendingStatus = () => ({
  type: CHECKOUT_PAYPAL_CONFIRM_ON_PENDING_STATUS
});
export const onPaypalAuthenticationNotApproved = () => ({
  type: CHECKOUT_PAYPAL_AUTHENTICATION_NOT_APPROVED
});

// ----- vip enrollment at checkout
export const onToggleVipEnrollmentCheckbox = isVipEnrollmentSelected => ({
  type: CHECKOUT_UPDATE_VIP_PREFERENCE,
  isVipEnrollmentSelected
});
export const trackVipTermsClick = () => ({ type: CHECKOUT_VIP_TERMS_CLICK });

// ----- for misc eventing
export const onAsinHasGoneOos = asin => ({
  type: CHECKOUT_ON_ASIN_HAS_GONE_OOS,
  asin
});
export const onCheckoutJustLoaded = () => ({ type: CHECKOUT_JUST_LOADED });
export const onHadConstraintViolation = constraintViolation => ({
  type: CHECKOUT_TRACK_CV,
  constraintViolation
});
export const onUseAsDefaultsLoad = useAsDefaults => ({
  type: SET_CHECKOUT_USE_AS_DEFAULTS,
  useAsDefaults
});
export const onUseAsDefaultsToggle = useAsDefaults => ({
  type: SET_CHECKOUT_USE_AS_DEFAULTS,
  useAsDefaults
});

// ----- configuring the purchase doc
export const configurePurchase = ({
  autoPlaceOrder,
  clearPurchaseDoc,
  clearAmazonPaySession,
  amazonCheckoutSessionId,
  addressId,
  advanceOnSuccess,
  coupon,
  totalClickCount: totalClickCountOfPromoSubmitButton,
  giftOptions,
  includePaymentsAndAddresses,
  paymentMethods,
  purchaseId,
  quantityUpdate,
  shipmentOptionId,
  shipmentOptionLineItemIds,
  isRemovingGiftOptions,
  isSavingGiftOptions,
  filterShipOptionsOnFirstLoad,
  includeAllPaymentTypes,
  confirmPayPalParams,
  refreshPurchase
}) => {
  /* Leaving this comment in here for now, as we still need to decide if we'll bother using some of the flags below:
    reqData = {
      useGCBalance: true,
      useDiscount: true,
      purchaseId: null
    },
    includeAssociated = true,
    [x added below] includePaymentsAndAddresses = false,
  */
  let reqData = {
    purchaseId
  };

  if (quantityUpdate) {
    reqData.quantityUpdate = quantityUpdate;
  }

  if (coupon) {
    reqData.coupon = coupon;
    reqData.totalClickCountOfPromoSubmitButton = totalClickCountOfPromoSubmitButton;
  }

  if (addressId) {
    reqData.addressId = addressId;
  }

  if (paymentMethods) {
    reqData.paymentMethods = paymentMethods;
  }

  if (amazonCheckoutSessionId) {
    reqData.amazonCheckoutSessionId = amazonCheckoutSessionId;
  }

  if (shipmentOptionId) {
    reqData.shipmentOptionId = shipmentOptionId;
  }

  if (shipmentOptionLineItemIds) {
    reqData.shipmentOptionLineItemIds = shipmentOptionLineItemIds;
  }

  if (giftOptions) {
    reqData.giftOptions = giftOptions;
  }

  if (isRemovingGiftOptions) {
    reqData.isRemovingGiftOptions = isRemovingGiftOptions;
  }

  if (isSavingGiftOptions) {
    reqData.isSavingGiftOptions = isSavingGiftOptions;
  }

  if (confirmPayPalParams) {
    reqData = {
      ...reqData,
      ...confirmPayPalParams
    };
  }

  if (refreshPurchase) {
    reqData.refreshPurchase = refreshPurchase;
  }

  const configureParams = {
    advanceOnSuccess,
    reqData
  };

  if (filterShipOptionsOnFirstLoad) {
    configureParams.filterShipOptionsOnFirstLoad = true;
  }

  if (includePaymentsAndAddresses) {
    configureParams.includePaymentsAndAddresses = true;
  }

  if (includeAllPaymentTypes) {
    configureParams.includeAllPaymentTypes = true;
  }

  if (clearPurchaseDoc) {
    configureParams.clearPurchaseDoc = true;
  }

  if (clearAmazonPaySession) {
    configureParams.clearAmazonPaySession = true;
  }

  if (autoPlaceOrder) {
    configureParams.autoPlaceOrder = true;
  }

  return { type: CONFIGURE_CHECKOUT, configureParams };
};
